<template>
	<div class="row">
		<div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-2 mb-5"></div>

		<div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 mb-5">
			<div class="card mb-0">
				<div class="row align-items-center">
					<div class="header text-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 py-4">
						<img src="@/assets/images/dashboard/guarda.png" class="w-75"> 
					</div>
					<div class="body text-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3" v-if="server.owner == true">
						<h4>{{$t("addServer.titlePageAdd")}}</h4>
						<p>{{ $t("addServer.subTitlePageAdd") }}</p>

						<div class="row">
							<div class="col-12">
								<div class="profile-header d-flex justify-content-between justify-content-center">
									<div class="d-flex w-100 row mt-4">
										<div class="mr-3 col-12 text-center">
											<img :src="'https://cdn.discordapp.com/icons/' + server.id + '/' + server.icon + '.png'" class="rounded" alt="" @error="imageError">
										</div>
										<div class="details w-100 col-12 mt-3 text-center">
											<h5 class="mb-0">{{ server.name }}</h5>
										</div> 
									</div>
								</div>
								<div class="w-100 p-3">
									<button type="button" class="btn btn-primary btn-lg btn-block mb-2 button_liberacao" @click="liberarServerLink(server)">
										<span>{{ $t("addServer.btnTitle") }}</span> <i class="icon-control-play ml-2"></i>
									</button>
								</div>
							</div>
							<div class="col-12 p-1">
								<div class="mt-2">
									<h6>{{ $t("addServer.attentionNote.title") }}</h6>
									<p>{{ $t("addServer.attentionNote.subTitle") }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="body text-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3" v-else>
						<div class="row">
							<div class="col-12">
								<div class="profile-header d-flex justify-content-between justify-content-center">
									<div class="d-flex w-100 row mt-4">
										<div class="mr-3 col-12 text-center">
											<img :src="'https://cdn.discordapp.com/icons/' + server.id + '/' + server.icon + '.png'" class="rounded" alt="" @error="imageError">
										</div>
										<div class="details w-100 col-12 mt-3 text-center">
											<h5 class="mb-0">{{ server.name }}</h5>
										</div> 
									</div>
								</div>
								<div class="w-100 p-3">
									<button type="button" class="btn btn-primary btn-lg btn-block mb-2 button_liberacao" @click="backRoute">
										<span>{{ $t("addServer.denyNote.btnNote") }}</span>
									</button>
								</div>
							</div>
							<div class="col-12 p-1">
								<div class="mt-2">
									<h6>{{ $t("addServer.denyNote.title") }}</h6>
									<p>{{ $t("addServer.denyNote.subTitle") }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'Add_deck_to_server',
	data: function () {
		return {
			server: {}
		}
	},
	computed: {
		...mapState({
			redirectUri:  state => state.redirectUri,
			clientId:  state => state.clientId,
			serverS: state => state.serverS
		})
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		backRoute : function () {
			router.push('/')
		},
		liberarServerLink : function (s) {
			window.location.href = "https://discord.com/api/oauth2/authorize?client_id="+ this.clientId +"&permissions=8&redirect_uri="+ this.redirectUri +"&response_type=code&scope=identify%20guilds%20email%20bot&guild_id="+ s.id
		}
	},
	mounted() {
		this.server = this.$route.params.server
	}
}

</script>

<style scoped>

.row {
	margin: 0;
}

.row>div {
	padding: 0;
}

h6 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button_liberacao {
	height: 70px !important;
	font-size: 24px;
}

</style>